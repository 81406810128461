import { ImageListType, ImageType } from "react-images-uploading";

import { IReferencedImage } from "./validateJSON";

export interface IValidateImagesResult {
  validImages: boolean;
  errorTitle: string;
  errorMessage: string;
}

export function validateImages(
  uploadedImages: ImageListType,
  referencedImages: IReferencedImage[]
): IValidateImagesResult {
  const imagesUploaded: string[] = getImagesNames(uploadedImages);
  if (hasDuplicates(imagesUploaded)) {
    return {
      validImages: false,
      errorTitle: "Upload fehlerhaft",
      errorMessage: "Mehrere Bilder haben denselben Namen!",
    };
  }

  const referencedImagesArray: string[] = referencedImages.map((i) => i.filename);

  const notUploadedButReferenced = referencedImagesArray
    .concat(imagesUploaded)
    .filter((v) => referencedImagesArray.includes(v) && !imagesUploaded.includes(v));

  const uploadedButNotReferenced = imagesUploaded
    .concat(referencedImagesArray)
    .filter((v) => imagesUploaded.includes(v) && !referencedImagesArray.includes(v));

  if (notUploadedButReferenced.length > 0 && uploadedButNotReferenced.length > 0) {
    const errorMessage =
      "Folgende Bilder werden im JSON referenziert, wurden jedoch nicht hochgeladen: " +
      notUploadedButReferenced.map((x) => "<br />" + x).toString() +
      "<br /><br />Folgende Bilder wurden hochgeladen, werden jedoch nicht im JSON referenziert: " +
      uploadedButNotReferenced.map((x) => "<br />" + x).toString();
    return { validImages: false, errorTitle: "Upload fehlerhaft", errorMessage: errorMessage };
  }
  if (notUploadedButReferenced.length > 0) {
    const errorMessage =
      "Folgende Bilder werden im JSON referenziert, wurden jedoch nicht hochgeladen: " +
      notUploadedButReferenced.map((x) => "<br />" + x).toString();
    return { validImages: false, errorTitle: "Upload unvollständig", errorMessage: errorMessage };
  }
  if (uploadedButNotReferenced.length > 0) {
    const errorMessage =
      "Folgende Bilder wurden hochgeladen, werden jedoch nicht im JSON referenziert: " +
      uploadedButNotReferenced.map((x) => "<br />" + x).toString();
    return { validImages: false, errorTitle: "Upload fehlerhaft", errorMessage: errorMessage };
  }
  return { validImages: true, errorTitle: "", errorMessage: "" };
}

export const getImagesNames = (images: ImageListType): string[] => {
  const imageNamesArray: string[] = [];
  for (let i = 0; i < images.length; i++) {
    const image: ImageType = images[i];
    if (image.file) {
      imageNamesArray.push(image.file.name);
    }
  }
  return imageNamesArray;
};

export const hasDuplicates = (Images: string[]) => {
  return new Set(Images).size !== Images.length;
};
