import React from "react";

import dayjs from "dayjs";
import { NavLink } from "react-router-dom";

import { ContentStatus, ContentType } from "src/configuration/types";
import "src/styling/scss/text.scss";
import "src/styling/scss/upload.scss";
import "src/styling/scss/uploadedContent.scss";

import { FunctionalIconBar } from "./functionalIconBar";
import { TypeIcon } from "./typeIcon";

export interface IContentInformation {
  contentName: string;
  contentStatus: ContentStatus;
  imageAmount: number;
  contentType: ContentType;
  publishedTime?: string;
  id: string;
  endOfUrl: string;
  content?: string;
  publishedEndOfUrl?: string;
  lastModified: Date;
  createdDate: Date;
  publishedAt: Date;
}

interface IUploadedContent {
  contentInformation: IContentInformation;
  reload: () => void;
}

export const UploadedContent: React.FC<{ filteredContent: IContentInformation[]; reload: () => void }> = ({
  filteredContent,
  reload,
}) => {
  return (
    <table>
      <thead>
        <tr>
          <td>Status</td>
          <td>Name</td>
          <td>Images</td>
          <td>Content-Type</td>
          <td>Created Date</td>
          <td>Last Modified</td>
          <td>Published At</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        {filteredContent.map((value, index) => (
          <UploadedContentRow key={`overview-table-row-${index}`} contentInformation={value} reload={reload} />
        ))}
      </tbody>
    </table>
  );
};

const UploadedContentRow: React.FC<IUploadedContent> = ({ contentInformation, reload }) => {
  return (
    <tr>
      <td>
        <TypeIcon currentContentStatus={contentInformation.contentStatus} />
      </td>
      <td>
        <NavLink to={`/content/${contentInformation.id}`} className="jsonName">
          {contentInformation.contentName.length > 60
            ? contentInformation.contentName.substring(0, 60) + "..."
            : contentInformation.contentName}
        </NavLink>
      </td>
      <td>
        <label className="contentCell light">{contentInformation.imageAmount} Bild(er)</label>
      </td>
      <td>
        <label className="contentCell contentType light">{contentInformation.contentType}</label>
      </td>
      <td>
        <label className="contentCell light">{dateToString(contentInformation.createdDate)}</label>
      </td>
      <td>
        <label className="contentCell light">{dateToString(contentInformation.lastModified)}</label>
      </td>
      <td>
        <label className="contentCell light">{dateToString(contentInformation.publishedAt)}</label>
      </td>
      <td>
        <FunctionalIconBar
          currentContentStatus={contentInformation.contentStatus}
          id={contentInformation.id}
          contentName={contentInformation.contentName}
          contentType={contentInformation.contentType}
          reload={reload}
          endOfUrl={
            contentInformation.publishedEndOfUrl ? contentInformation.publishedEndOfUrl : contentInformation.endOfUrl
          }
        />
      </td>
    </tr>
  );
};

export function dateToString(date: Date): string {
  return dayjs(date).format("DD.MM.YY | HH:mm:ss");
}
