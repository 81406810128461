import { config } from "src/configuration";
import { ContentType } from "src/configuration/types";

import { archiveContentByID, deleteContentByID, publishContentByID } from "./apiCalls";
import { buildPreviewURL, buildPublishedURL } from "./buildURL";
import { Notify, confirmDelete, fireAlertFailedDelete } from "./notification";

export function openPreview(contentId: string | undefined, contentType: ContentType): void {
  let url: string;
  if (contentId?.includes(config.contentApiUrl)) {
    url = buildPreviewURL(contentId, contentType);
  } else {
    url = buildPreviewURL(config.contentApiUrl + "/" + contentId, contentType);
  }
  window.open(url);
}

export function openLink(endOfUrl: string, contentType: ContentType) {
  const url = buildPublishedURL(endOfUrl, contentType);
  window.open(url);
}

// TODO: global error handler with error logging
export async function publish(id: string | undefined) {
  if (id == null || id === "") {
    return Notify.error("Veröffentlichen fehlgeschlagen", "Die ID ist nicht definiert.");
  }

  try {
    await publishContentByID(id);
    Notify.success("Veröffentlichen erfolgreich");
  } catch {
    Notify.error("Veröffentlichen fehlgeschlagen");
  }
}

export async function archive(id: string) {
  try {
    await archiveContentByID(id);
    Notify.success("Archivieren erfolgreich");
  } catch {
    Notify.error("Archivieren fehlgeschlagen");
  }
}

export async function deleteContent(id: string): Promise<boolean> {
  const confirmTitle = "Content löschen";
  const confirmText = "Willst du wirklich den gesamten Content unwiderruflich löschen?";
  if (await confirmDelete(confirmTitle, confirmText)) {
    try {
      await deleteContentByID(id);
      Notify.success("Löschen erfolgreich");
      return true;
    } catch (e) {
      fireAlertFailedDelete();
      return false;
    }
  }
  return false;
}
