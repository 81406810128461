import React from "react";

import { faCheckCircle, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faArchive, faExclamationTriangle, faPencilRuler } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ContentStatus } from "src/configuration/types";

export const TypeIcon: React.FC<{ currentContentStatus: ContentStatus; text?: boolean }> = ({
  currentContentStatus,
  text,
}) => {
  if (currentContentStatus === "draft") {
    return (
      <div>
        <FontAwesomeIcon icon={faPencilRuler} className="icon draft" title="Entwurf" />
        {text && (
          <label className="icon draft">
            {" "}
            Deine Daten sind als Entwurf gespeichert, aber noch nicht veröffentlicht.
          </label>
        )}
      </div>
    );
  } else if (currentContentStatus === "published") {
    return (
      <div>
        <FontAwesomeIcon icon={faCheckCircle} className="icon published" title="Veröffentlicht" />
        {text && <label className="icon published"> Deine Daten sind veröffentlicht.</label>}
      </div>
    );
  } else if (currentContentStatus === "rework") {
    return (
      <div>
        <FontAwesomeIcon icon={faExclamationTriangle} className="icon rework" title="Überarbeitung" />
        {text && (
          <label className="icon rework">
            {" "}
            Es wurden Dateien überarbeitet. Die Änderungen sind noch nicht veröffentlicht.
          </label>
        )}
      </div>
    );
  } else if (currentContentStatus === "archived") {
    return (
      <div>
        <FontAwesomeIcon icon={faArchive} className="icon archived" title="Archiviert" />
        {text && <label className="icon archived"> Die ausgewählten Dateien sind archiviert.</label>}
      </div>
    );
  } else {
    return (
      <div>
        <FontAwesomeIcon icon={faQuestionCircle} className="icon unknown" title="Unbekannter Status" />
        {text && <label className="icon unknown"> Der Status der Dateien ist unbekannt.</label>}
      </div>
    );
  }
};
