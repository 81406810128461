import React from "react";

import { faFile } from "@fortawesome/free-regular-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import Button from "src/components/button";
import { ul } from "src/functions/htmlutils";
import { loadFileContents } from "src/functions/loadFileContents";
import { fireAlertDeleteJSON, fireAlertFailedUploadJSON, generalErrorAlert } from "src/functions/notification";
import { IArticle, IReferencedImage, validateJSON } from "src/functions/validator/validateJSON";
import "src/styling/scss/upload.scss";

interface UploadContentProps {
  setUploadedContent: (content: IArticle | null) => void;
  setReferencedImages: (referencedImages: IReferencedImage[]) => void;
  file: IArticle | null;
}

export const UploadJSON: React.FC<UploadContentProps> = ({ setUploadedContent, setReferencedImages, file }) => {
  const handleDelete = () => {
    fireAlertDeleteJSON(deleteJSON);
  };

  const deleteJSON = () => {
    setReferencedImages([]);
    setUploadedContent(null);
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setReferencedImages([]);
    setUploadedContent(null);

    if (!(e.target.files != null && e.target.files.length > 0)) {
      fireAlertFailedUploadJSON();
      return;
    }

    const result = await loadFileContents(e.target.files[0]);

    const validationResult = validateJSON(result.toString());

    if (Array.isArray(validationResult)) {
      generalErrorAlert.fire({
        title: "Upload fehlgeschlagen",
        html: ul(validationResult).html,
      });
    } else {
      setUploadedContent(validationResult.content);
      setReferencedImages(validationResult.images);
    }

    e.target.value = "";
  };
  if (file !== null) {
    return (
      <div className="uploadBox">
        <label className="jsonName">{file.header.endOfUrl + ".json"}</label>
        &nbsp;
        <Button
          name=""
          title="JSON löschen"
          border="transparent"
          className="uploadButtonInverse"
          icon={faTrashAlt}
          iconPosition="center"
          onClick={handleDelete}
        />
      </div>
    );
  } else {
    return (
      <div className="uploadBox">
        <input
          type="file"
          id="selectedFile"
          data-testid="uploadFileElement"
          onChange={handleChange}
          style={{ display: "none" }}
        />
        <Button
          name="JSON auswählen"
          className="uploadButton"
          onClick={() => {
            document.getElementById("selectedFile")!.click();
          }}
          icon={faFile}
          iconPosition="left"
        />
      </div>
    );
  }
};
