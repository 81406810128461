import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";

import Button from "src/components/button";

export const LogoutButton: React.FC = () => {
  const { logout } = useAuth0();
  return (
    <Button
      name="Logout"
      className="menuButton"
      onClick={() => logout()}
      icon={faPowerOff}
      iconPosition="left"
      float="right"
    />
  );
};
