import React from "react";

import "src/styling/scss/loginScreen.scss";
import "src/styling/scss/text.scss";

import LoginButton from "./loginButton";

const LoginScreen: React.FunctionComponent = () => {
  return (
    <div className="window">
      <div>
        <h2 className="currentText">Herzlich Willkommen auf der Startseite des Contenthubs!</h2>
        <p className="currentText">Bitte logge dich zunächst ein.</p>
      </div>
      <LoginButton />
    </div>
  );
};

export default LoginScreen;
