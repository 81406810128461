//https://codesandbox.io/s/react-images-uploading-demo-typescript-fr2zm?file=/src/App.tsx
import React, { useState } from "react";

import { faImage, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import ImageUploading, { ImageListType, ImageType } from "react-images-uploading";

import Button from "src/components/button";
import { fireAlertDeleteAllImages, fireAlertDeleteImage } from "src/functions/notification";
import { IReferencedImage } from "src/functions/validator/validateJSON";
import "src/styling/scss/upload.scss";

interface UploadImagesProps {
  setUploadedImages: (imageList: ImageListType) => void;
  images: ImageListType;
  referencedImages: IReferencedImage[];
}

interface IRequiredImageSize {
  width: number;
  height: number;
}

const requiredImageSizes: Record<string, IRequiredImageSize> = {
  "mainContent.tileImage": {
    width: 540,
    height: 322,
  },
  "mainContent.static.slider.*.imagesUrl.*": {
    width: 1200,
    height: -1,
  },
  "mainContent.static.tweets.*.image": {
    width: 600,
    height: -1,
  },
  "mainContent.static.scope.backgroundImage": {
    width: 1920,
    height: 1080,
  },
  "mainContent.hero.image": {
    width: 1200,
    height: -1,
  },
};

const UploadImageWarning: React.FC<{
  filename?: string;
  referencedImages: IReferencedImage[];
  width?: number;
  height?: number;
  fileSize?: number;
}> = ({ filename, referencedImages, width, height, fileSize }) => {
  if (filename === undefined || width === undefined || height === undefined) {
    return null;
  }

  const referencedImage = referencedImages.find((ri) => ri.filename === filename);
  if (referencedImage === undefined) {
    return <p className="error">Dieses Bild wird nicht im JSON referenziert.</p>;
  } else {
    const errors: string[] = [];
    const maskedJsonPath = referencedImage.jsonPath.replaceAll(/\d/g, "*");
    const requiredImageSize = requiredImageSizes[maskedJsonPath];
    if (requiredImageSize !== undefined) {
      const isInvalid =
        (requiredImageSize.width !== -1 && requiredImageSize.width !== width) ||
        (requiredImageSize.height !== -1 && requiredImageSize.height !== height);
      if (isInvalid) {
        const expectedSize =
          requiredImageSize.width !== -1 && requiredImageSize.height !== -1
            ? `${requiredImageSize.width}x${requiredImageSize.height}px`
            : requiredImageSize.width !== -1
            ? `${requiredImageSize.width}px breit`
            : `${requiredImageSize.height}px hoch`;

        errors.push(`Dieses Bild hat eine falsche Auflösung. Erwartet: ${expectedSize}`);
      }
    }
    if (fileSize !== undefined && fileSize > 1024 * 1024) {
      const mb = fileSize / 1024 / 1024;

      errors.push(`Dieses Bild ist sehr groß: ${mb.toFixed(2)}MB`);
    }
    if (errors.length > 0) {
      return (
        <>
          {errors.map((e) => (
            <p className="error">{e}</p>
          ))}
        </>
      );
    }
  }

  return null;
};

const getFileSize = async (url: string): Promise<number> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("HEAD", url, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const responseHeader = xhr.getResponseHeader("Content-Length");
          if (responseHeader === null) {
            reject();
          } else {
            resolve(parseInt(responseHeader));
          }
        } else {
          reject();
        }
      }
    };
    xhr.send(null);
  });
};

const PreviewImage: React.FC<{ image: ImageType; referencedImages: IReferencedImage[]; onRemove: () => void }> = ({
  image,
  referencedImages,
  onRemove,
}) => {
  const [width, setWidth] = useState<number | undefined>();
  const [height, setHeight] = useState<number | undefined>();
  const [fileSize, setFileSize] = useState<number | undefined>();

  return (
    <div className="image-item">
      <div className="deleteAndImage">
        <Button
          name=""
          border="transparent"
          title="Bild löschen"
          className="uploadButtonInverse"
          icon={faTrashAlt}
          iconPosition="left"
          onClick={onRemove}
        />
        <img
          className="image"
          src={image.dataURL}
          alt=""
          onLoad={(event) => {
            setWidth((event.target as HTMLImageElement).naturalWidth);
            setHeight((event.target as HTMLImageElement).naturalHeight);
            if (image.dataURL?.startsWith("http")) {
              getFileSize(image.dataURL).then(setFileSize);
            } else {
              setFileSize(image.file?.size);
            }
          }}
        />
      </div>
      <div className="image-name">
        <p>{image.file?.name}</p>
        <UploadImageWarning
          filename={image.file?.name}
          referencedImages={referencedImages}
          width={width}
          height={height}
          fileSize={fileSize}
        />
      </div>
    </div>
  );
};

export const UploadImages: React.FC<UploadImagesProps> = ({ setUploadedImages, images, referencedImages }) => {
  const onChange = (imageList: ImageListType) => {
    setUploadedImages(imageList);
  };

  return (
    <div className="UploadBilder">
      <ImageUploading multiple={true} value={images} onChange={onChange}>
        {({ imageList, onImageUpload, onImageRemoveAll, onImageRemove }) => (
          <div className="upload__image-wrapper">
            <div className="upload_static-buttons">
              <Button
                name="Bilder auswählen"
                className="uploadButton"
                icon={faImage}
                iconPosition="left"
                onClick={onImageUpload}
              />
              &nbsp;
              <Button
                name="Alle löschen"
                title="alle Bilder löschen"
                className="uploadButton"
                icon={faTrashAlt}
                iconPosition="right"
                onClick={() => {
                  fireAlertDeleteAllImages(onImageRemoveAll);
                }}
              />
            </div>

            <div className="image-items">
              {imageList.map((image, index) => (
                <PreviewImage
                  key={index}
                  referencedImages={referencedImages}
                  image={image}
                  onRemove={() => {
                    fireAlertDeleteImage(onImageRemove, index);
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  );
};
