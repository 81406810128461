import React from "react";


import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { Navigate, Outlet, Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { registerGlobalWaitCursorInterceptor } from "src/axios/registerGlobalWaitCursorInterceptor";
import "src/styling/scss/app.scss";

import { useGlobalRegisterAuthenticationInterceptor } from "./axios/useGlobalRegisterAuthenticationInterceptor";
import { ContactOverview } from "./components/contactOverview";
import { ContentDetail } from "./components/contentDetail";
import { ContentOverview } from "./components/contentOverview";
import Header from "./components/header";
import { NavigationBar } from "./components/header/navigationBar";
import LoginScreen from "./components/loginScreen";
import { NewUpload } from "./components/newUpload";

registerGlobalWaitCursorInterceptor(axios);

const App: React.FunctionComponent = () => {
  useGlobalRegisterAuthenticationInterceptor(axios);

  const { isAuthenticated, isLoading } = useAuth0();

  function RequireAuth() {
    if (!isAuthenticated) {
      return <Navigate to="/login" />;
    }
    return <Outlet />;
  }

  if (isLoading)
    return (
      <div>
        <Header />
        <div className="container" id="hintergrund">
          Logging in
        </div>
      </div>
    );

  return (
    <Router>
      <div className="container" id="hintergrund">
        <Header />
        {isAuthenticated && <NavigationBar />}
        <div className="currentTab">
          <Routes>
            <Route path="/login" element={<LoginScreen />} />
            <Route element={<RequireAuth />}>
              <Route path="/" element={<Navigate replace to="/content" />} />
              <Route path="/contacts" element={<ContactOverview />} />
              <Route path="/content" element={<ContentOverview />} />
              <Route path="/upload" element={<NewUpload />} />
              <Route path="/content/:id" element={<ContentDetail />} />
            </Route>
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
