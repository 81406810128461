import swal from "sweetalert2";

import { MaybeHtml, ValidHtml } from "src/functions/htmlutils";

const successfullyToast = swal.mixin({
  timer: 3000,
  toast: true,
  position: "top-end",
  icon: "success",
  timerProgressBar: true,
  showConfirmButton: false,
  showCloseButton: true,
});

export const generalErrorAlert = swal.mixin({ icon: "error", confirmButtonColor: "rgb(212, 216, 0)" });

export const Notify = {
  error(title: string, contents?: MaybeHtml) {
    if (contents instanceof ValidHtml) {
      generalErrorAlert.fire({ title, html: contents.html });
    } else {
      generalErrorAlert.fire({ title, text: contents });
    }
  },
  success(title: string) {
    successfullyToast.fire({ title });
  },
};

// TODO: move this somewhere else or even inline it
export function fireToastSuccessfullyUploaded() {
  Notify.success("Upload erfolgreich");
}

// TODO: move this somewhere else or inline it
export function fireAlertMissingJSON() {
  Notify.error("Upload fehlgeschlagen", "Du hast keine JSON-Datei hochgeladen!");
}

// TODO: move this somewhere else or inline it
export function fireAlertFailedUpload(text?: string) {
  if (text) {
    // TODO: escape "text", but make sure that all callers use "htmlutils" to create valid html
    generalErrorAlert.fire({ title: "Upload fehlgeschlagen", html: "<p>" + text + "</p>" });
  } else {
    generalErrorAlert.fire({ title: "Upload fehlgeschlagen" });
  }
}

// TODO: move this somewhere else or inline it
export function fireAlertFailedDelete() {
  generalErrorAlert.fire({ title: "Löschen fehlgeschlagen" });
}

// TODO: Create utility function for confirmations
export const deleteRequestAlert = swal.mixin({
  icon: "warning",
  showCancelButton: true,
  showConfirmButton: true,
  cancelButtonText: "Abbrechen",
  confirmButtonText: "Löschen",
  cancelButtonColor: "grey",
  confirmButtonColor: "red",
});

export async function confirmDelete(title: string, text: string): Promise<boolean> {
  const result = await deleteRequestAlert.fire({ title, text });
  return result.isConfirmed;
}

// TODO: Move elsewhere or inline
async function deleteRequest(
  deleteFunction: () => void | Promise<void>,
  title: string,
  text: string,
  successTitle: string
) {
  if (await confirmDelete(title, text)) {
    await deleteFunction();
    await successfullyToast.fire({ title: successTitle });
  }
}

// TODO: move elsewhere or inline
export function fireAlertDeleteImage(onImageRemove: (index: number) => void, index: number) {
  function handleChange() {
    onImageRemove(index);
  }
  deleteRequest(
    handleChange,
    "Bild löschen",
    "Willst du das Bild wirklich löschen?",
    "Das Bild wurde erfolgreich gelöscht"
  );
}

// TODO: Move elsewhere or inline
export function fireAlertDeleteAllImages(onImageRemoveAll: () => void) {
  const deleteImage = swal.mixin({
    title: "Alle Bilder löschen",
    text: "Willst du wirklich alle Bilder löschen?",
    icon: "warning",
    showCancelButton: true,
    showConfirmButton: true,
    cancelButtonText: "Abbrechen",
    confirmButtonText: "Löschen",
    cancelButtonColor: "grey",
    confirmButtonColor: "red",
  });

  deleteImage.fire().then((result) => {
    if (result.isConfirmed) {
      onImageRemoveAll();
      successfullyToast.fire({ title: "Alle Bilder wurden gelöscht" });
    }
  });
}

// TODO: Move elsewhere or inline
export function fireAlertDeleteJSON(deleteJSON: () => void) {
  const deleteImage = swal.mixin({
    title: "Datei löschen",
    text: "Willst du wirklich die JSON-Datei löschen?",
    icon: "warning",
    showCancelButton: true,
    showConfirmButton: true,
    cancelButtonText: "Abbrechen",
    confirmButtonText: "Löschen",
    cancelButtonColor: "grey",
    confirmButtonColor: "red",
  });

  deleteImage.fire().then((result) => {
    if (result.isConfirmed) {
      deleteJSON();
      successfullyToast.fire({ title: "Die JSON-Datei wurde gelöscht" });
    }
  });
}

// TODO: move move elsewhere or inline
export function fireAlertGeneralError(title?: string, text?: string) {
  // TODO: escape "text" but make sure that all callers use "htmlutils"
  generalErrorAlert.fire({ title: title, html: "<p>" + text + "</p>" });
}

// TODO: move elsewhere or inline
export function fireAlertFailedUploadJSON() {
  Notify.error("JSON upload fehlgeschlagen");
}
