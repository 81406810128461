import React from "react";

import { useAuth0 } from "@auth0/auth0-react";

import Button from "./button";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return <Button name="Login via Gitlab" className="loginButton" onClick={() => loginWithRedirect()} />;
};

export default LoginButton;
