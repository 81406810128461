import React, { useEffect, useState } from "react";

import { ContactsTable, IContact } from "src/components/contactOverview/contacts";
import { getAllContacts } from "src/functions/apiCalls";

export const ContactOverview: React.FC = () => {
  const [contacts, setContacts] = useState<IContact[]>([]);

  useEffect(() => {
    getAllContacts().then((value) => setContacts(value));
  }, [setContacts]);

  return (
    <div className="contentOverview">
      <div>
        <h2 className="currentText">Kontaktanfragen</h2>
        <p className="currentText">Hier findest du alle Kontaktanfragen.</p>
      </div>

      <ContactsTable contacts={contacts} />
    </div>
  );
};
