import { ImageListType } from "react-images-uploading";

import { config } from "src/configuration";
import { ContentStatus } from "src/configuration/types";
import { deleteImages, postUploadedImages, postUploadedJSON } from "src/functions/apiCalls";
import { fireAlertGeneralError, fireAlertMissingJSON, fireToastSuccessfullyUploaded } from "src/functions/notification";
import { validateImages } from "src/functions/validator/validateImages";
import { IArticle, IReferencedImage } from "src/functions/validator/validateJSON";

export async function saveChanges(
  uploadedImages: ImageListType,
  referencedImages: IReferencedImage[],
  initialImages: IReferencedImage[],
  id: string,
  currentStatus: ContentStatus,
  uploadedContent: IArticle | null
): Promise<boolean> {
  if (uploadedContent === null) {
    fireAlertMissingJSON();
    return false;
  }

  const validateImagesResult = validateImages(uploadedImages, referencedImages);
  if (validateImagesResult.validImages) {
    const numberOfImages = referencedImages.length;
    const contentStatus: ContentStatus = getNewStatus(currentStatus);
    const dbJson = {
      content: uploadedContent,
      numberOfImages: numberOfImages,
      contentStatus: contentStatus,
      id: id,
    };
    const response = await postUploadedJSON(dbJson);
    const newUploadImages = uploadedImages.filter((value) => {
      return !value.dataURL?.startsWith(config.imageS3Bucket);
    });
    if (response !== undefined) {
      await postUploadedImages(newUploadImages, dbJson.id);
      const referencedImagesArray: string[] = referencedImages.map((i) => i.filename);
      const imagesToDelete = initialImages
        .map((i) => i.filename)
        .concat(referencedImagesArray)
        .filter((v) => !referencedImagesArray.includes(v));
      await deleteImages(imagesToDelete, id);
      fireToastSuccessfullyUploaded();
      return true;
    }
  } else {
    fireAlertGeneralError(validateImagesResult.errorTitle, validateImagesResult.errorMessage);
  }
  return false;
}

function getNewStatus(currentStatus: ContentStatus) {
  if (currentStatus === "published") {
    return "rework";
  }
  if (currentStatus === "archived") {
    return "draft";
  }
  return currentStatus;
}
