import { AxiosInstance } from "axios";

export function registerGlobalWaitCursorInterceptor(axios: AxiosInstance) {
  let pendingRequests = reactiveCounter((currentValue) => {
    document.body.style.cursor = currentValue > 0 ? "wait" : "default";
  });

  axios.interceptors.request.use((requestConfig) => {
    pendingRequests.increment();
    return requestConfig;
  });

  axios.interceptors.response.use(
    (response) => {
      pendingRequests.decrement();
      return response;
    },
    (error) => {
      pendingRequests.decrement();
      return Promise.reject(error);
    }
  );
}

function reactiveCounter(callback: (requestsPending: number) => void) {
  let counter = 0;
  return {
    increment() {
      counter++;
      callback(counter);
    },
    decrement() {
      counter--;
      callback(counter);
    },
  };
}
