import React from "react";

import { Auth0Provider } from "@auth0/auth0-react";
import ReactDOM from "react-dom";


import App from "./App";
import { config } from "./configuration";
import "./index.css";

ReactDOM.render(
  <Auth0Provider
    domain={config.auth0Domain}
    clientId={config.auth0ClientId}
    authorizationParams={{
      audience: config.audience,
      redirect_uri: window.location.origin,
    }}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);
