import React, { useCallback, useEffect, useState } from "react";

import { ContentStatus } from "src/configuration/types";
import { getAllContent } from "src/functions/apiCalls";
import { Notify } from "src/functions/notification";
import "src/styling/scss/contentOverview.scss";
import "src/styling/scss/published.scss";
import "src/styling/scss/text.scss";

import { Filter, getFilteredStatusList, getFilteredTextList } from "./filter";
import { IContentInformation, UploadedContent } from "./uploadedContent";

export interface IFilter {
  filteredContent: IContentInformation[];
  filterStatus: ContentStatus | null;
  filterText: string;
}

export const ContentOverview: React.FC = () => {
  const [allContent, setAllContent] = useState<IContentInformation[]>([]);
  const [filterInformation, setFilterInformation] = useState<IFilter>({
    filteredContent: [],
    filterStatus: null,
    filterText: "",
  });

  const reload = useCallback((filterStatus: ContentStatus | null, filterText: string | null) => {
    getAllContent()
      .then((value) => {
        setAllContent(value);
        let filteredContent: IContentInformation[] = value;
        const text = filterText ? filterText : "";
        if (filterStatus !== null) {
          filteredContent = getFilteredStatusList(filterStatus, filteredContent);
        }
        if (filterText !== "") {
          filteredContent = getFilteredTextList(text, filteredContent);
        }
        setFilterInformation({ filteredContent: filteredContent, filterStatus: filterStatus, filterText: text });
      })
      .catch(() => {
        Notify.error("Reload fehlgeschlagen");
      });
  }, []);

  useEffect(() => {
    reload(null, null);
  }, [reload]);

  const content =
    filterInformation.filteredContent.length === 0 && allContent.length > 0 ? (
      <p className="currentText error">Unter dem ausgewählten Filter existiert kein Content in der Datenbank!</p>
    ) : (
      <UploadedContent
        filteredContent={filterInformation.filteredContent}
        reload={() => reload(filterInformation.filterStatus, filterInformation.filterText)}
      />
    );

  return (
    <div className="contentOverview">
      <div>
        <h2 className="currentText">Hochgeladener Content</h2>
        <p className="currentText">Hier findest du alle Entwürfe und veröffentlichten Inhalte auf einen Blick.</p>
      </div>
      <Filter
        setFilterInformation={setFilterInformation}
        allContent={allContent}
        filterInformation={filterInformation}
      />

      {content}
    </div>
  );
};
