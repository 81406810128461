import React from "react";


import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faArchive, faGlobe, faRocket, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import Button from "src/components/button";
import { ContentStatus, ContentType } from "src/configuration/types";
import { archive, deleteContent, openLink, openPreview, publish } from "src/functions/changeContentStatus";

export const FunctionalIconBar: React.FC<{
  currentContentStatus: ContentStatus;
  id: string;
  contentName: string;
  contentType: ContentType;
  reload: () => void;
  endOfUrl: string;
}> = ({ currentContentStatus, id, contentName, contentType, reload, endOfUrl }) => {
  if (currentContentStatus === "draft") {
    return (
      <FunctionalIcons
        preview={true}
        link={false}
        publishContent={true}
        archiveContent={false}
        deleteContent={true}
        id={id}
        contentName={contentName}
        contentType={contentType}
        reload={reload}
        endOfUrl={endOfUrl}
      />
    );
  } else if (currentContentStatus === "published") {
    return (
      <FunctionalIcons
        preview={false}
        link={true}
        publishContent={false}
        archiveContent={true}
        deleteContent={true}
        id={id}
        contentName={contentName}
        contentType={contentType}
        reload={reload}
        endOfUrl={endOfUrl}
      />
    );
  } else if (currentContentStatus === "rework") {
    return (
      <FunctionalIcons
        preview={true}
        link={true}
        publishContent={true}
        archiveContent={false}
        deleteContent={true}
        id={id}
        contentName={contentName}
        contentType={contentType}
        reload={reload}
        endOfUrl={endOfUrl}
      />
    );
  } else if (currentContentStatus === "archived") {
    return (
      <FunctionalIcons
        preview={true}
        link={false}
        publishContent={true}
        archiveContent={false}
        deleteContent={true}
        id={id}
        contentName={contentName}
        contentType={contentType}
        reload={reload}
        endOfUrl={endOfUrl}
      />
    );
  } else {
    return (
      <FunctionalIcons
        preview={false}
        link={false}
        publishContent={false}
        archiveContent={false}
        deleteContent={false}
        id={id}
        contentName={contentName}
        contentType={contentType}
        reload={reload}
        endOfUrl={endOfUrl}
      />
    );
  }
};

const FunctionalIcons: React.FC<{
  preview: boolean;
  link: boolean;
  publishContent: boolean;
  archiveContent: boolean;
  deleteContent: boolean;
  id: string;
  contentName: string;
  contentType: ContentType;
  reload: () => void;
  endOfUrl: string;
}> = ({ preview, link, publishContent, archiveContent, deleteContent, id, contentType, reload, endOfUrl }) => {
  return (
    <div className="functionalBox">
      <div className="functionalBar left">
        <PreviewButton active={preview} id={id} contentType={contentType} />
        <WebsiteButton active={link} endOfUrl={endOfUrl} contentType={contentType} />
      </div>
      <div className="functionalBar right">
        <PublishButton active={publishContent} id={id} reload={reload} />
        <ArchiveButton active={archiveContent} id={id} reload={reload} />
        <DeleteButton active={deleteContent} id={id} reload={reload} />
      </div>
    </div>
  );
};

const PreviewButton: React.FC<{ active: boolean; id: string; contentType: ContentType }> = ({
  active,
  id,
  contentType,
}) => {
  return (
    <Button
      name=""
      title="Vorschau"
      className="functionalIcon"
      onClick={() => openPreview(id, contentType)}
      icon={faEye}
      iconPosition="center"
      disabled={!active}
    />
  );
};

const WebsiteButton: React.FC<{ active: boolean; endOfUrl: string; contentType: ContentType }> = ({
  active,
  endOfUrl,
  contentType,
}) => {
  return (
    <Button
      name=""
      title="Webseite"
      className="functionalIcon"
      onClick={() => openLink(endOfUrl, contentType)}
      icon={faGlobe}
      iconPosition="center"
      disabled={!active}
    />
  );
};

const PublishButton: React.FC<{
  active: boolean;
  id: string;
  reload: () => void;
}> = ({ active, id, reload }) => {
  return (
    <Button
      name=""
      title="Veröffentlichen"
      className="functionalIcon"
      onClick={async () => {
        await publish(id);
        reload();
      }}
      icon={faRocket}
      iconPosition="center"
      disabled={!active}
    />
  );
};

const ArchiveButton: React.FC<{
  active: boolean;
  id: string;
  reload: () => void;
}> = ({ active, id, reload }) => {
  return (
    <Button
      name=""
      title="Archivieren"
      className="functionalIcon"
      onClick={async () => {
        await archive(id);
        reload();
      }}
      icon={faArchive}
      iconPosition="center"
      disabled={!active}
    />
  );
};

const DeleteButton: React.FC<{
  active: boolean;
  id: string;
  reload: () => void;
}> = ({ active, id, reload }) => {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/content`;
    navigate(path);
  };

  return (
    <Button
      name=""
      title="Löschen"
      className="functionalIcon"
      onClick={async () => {
        const deleteSuccessful = await deleteContent(id);
        if (deleteSuccessful) {
          const url = window.location.href;
          if (url.includes("/content")) {
            reload();
          } else {
            routeChange();
          }
        }
      }}
      icon={faTrashAlt}
      iconPosition="center"
      disabled={!active}
    />
  );
};
