import React, { useState } from "react";

import { faRocket, faUpload } from "@fortawesome/free-solid-svg-icons";
import { ImageListType } from "react-images-uploading";

import Button from "src/components/button";
import { postUploadedImages, postUploadedJSON } from "src/functions/apiCalls";
import { openPreview, publish } from "src/functions/changeContentStatus";
import { fireAlertGeneralError, fireAlertMissingJSON, fireToastSuccessfullyUploaded } from "src/functions/notification";
import { validateImages } from "src/functions/validator/validateImages";
import { IArticle, IReferencedImage } from "src/functions/validator/validateJSON";
import "src/styling/scss/upload.scss";

import { UploadImages } from "./uploadImages";
import { UploadJSON } from "./uploadJSON";

export interface uploaded {
  isUploaded: boolean;
  id: string | undefined;
}

export const NewUpload: React.FC = () => {
  const [uploadedImages, setUploadedImages] = useState<ImageListType>([]);
  const [uploadedContent, setUploadedContent] = useState<IArticle | null>(null);
  const [referencedImages, setReferencedImages] = useState<IReferencedImage[]>([]);
  const [uploaded, setUploaded] = useState<uploaded>({ isUploaded: false, id: "" });

  const upload = async (showPreview: boolean) => {
    if (uploadedContent !== null) {
      const validateImagesResult = validateImages(uploadedImages, referencedImages);
      if (validateImagesResult.validImages) {
        const numberOfImages = referencedImages.length;
        const contentStatus = "draft";
        const dbJson = { content: uploadedContent, numberOfImages, contentStatus };
        const response = await postUploadedJSON(dbJson);
        if (response !== undefined) {
          await postUploadedImages(uploadedImages, response.id);
          setUploaded({ isUploaded: true, id: response.id });
          if (showPreview) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            openPreview(response.id, dbJson.content.header.type);
          } else {
            fireToastSuccessfullyUploaded();
          }
        }
      } else {
        fireAlertGeneralError(validateImagesResult.errorTitle, validateImagesResult.errorMessage);
      }
    } else {
      fireAlertMissingJSON();
    }
  };

  return (
    <div className="newUpload">
      <h2 className="currentText">Daten-Upload</h2>
      <p className="currentText">Lade dein JSON-File und die dazugehörigen Bilder hoch.</p>
      <h5 className="currentText">Schritt 1:</h5>
      <div className="Upload">
        <UploadJSON
          setUploadedContent={setUploadedContent}
          setReferencedImages={setReferencedImages}
          file={uploadedContent}
        />

        <h5 className="currentText">Schritt 2:</h5>
        <UploadImages
          setUploadedImages={setUploadedImages}
          images={uploadedImages}
          referencedImages={referencedImages}
        />
      </div>

      <div className="processUpload">
        <Button
          name="Hochladen und Ansehen"
          className="submitButton"
          icon={faUpload}
          iconPosition="left"
          onClick={() => {
            upload(true);
          }}
          margin="10px"
        />
        <Button
          name="Veröffentlichen"
          className="submitButton"
          icon={faRocket}
          iconPosition="left"
          onClick={() => publish(uploaded.id)}
          disabled={!uploaded.isUploaded}
          margin="10px"
        />
      </div>
    </div>
  );
};
