import React from "react";

import dayjs from "dayjs";

import "src/styling/scss/contactOverview.scss";
import "src/styling/scss/text.scss";
import "src/styling/scss/upload.scss";

export interface IContact {
  contactDate: Date;
  mail: string;
  message: string;
  reason: string[];
  id: string;
  name: string;
  phone?: string;
}

interface IContactRow {
  contact: IContact;
}

const compareContacts = (a: IContact, b: IContact): number => {
  if (a.contactDate < b.contactDate) {
    return 1;
  }
  if (b.contactDate < a.contactDate) {
    return -1;
  }
  return 0;
};

export const ContactsTable: React.FC<{ contacts: IContact[] }> = ({ contacts }) => {
  return (
    <table>
      <tbody>
        {contacts.sort(compareContacts).map((value, index) => (
          <ContactRow key={`overview-table-row-${index}`} contact={value} />
        ))}
      </tbody>
    </table>
  );
};

const ContactRow: React.FC<IContactRow> = ({ contact }) => {
  return (
    <tr>
      <td className="noWrap">{contact.name}</td>
      <td className="noWrap">{dateToString(contact.contactDate)}</td>
      <td className="noWrap">{contact.mail}</td>
      <td className="noWrap">{contact.phone}</td>
      <td className="noWrap">{contact.reason.join(", ")}</td>
      <td>{contact.message}</td>
    </tr>
  );
};

export function dateToString(date: Date): string {
  return dayjs(date).format("DD.MM.YY | HH:mm:ss");
}
