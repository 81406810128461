// Contents of this class does not need to be escaped anymore
export class ValidHtml {
  readonly html: string;
  constructor(html: string) {
    this.html = html;
  }
}

export type MaybeHtml = string | ValidHtml;

export function escapeHtml(text: MaybeHtml): ValidHtml {
  if (text instanceof ValidHtml) {
    return text;
  }
  return new ValidHtml(
    text
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;")
  );
}

export function ul(items: MaybeHtml[]): ValidHtml {
  const listItems = items.map((item) => `<li style="white-space: pre-line">${escapeHtml(item).html}</li>`);
  return new ValidHtml(`<ul style="text-align: left;">${listItems.join("")}</ul>`);
}
