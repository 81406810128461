import { config } from "src/configuration";
import { ContentType } from "src/configuration/types";

export function buildPreviewURL(contentId: string | undefined, contentType: ContentType): string {
  return config.previewUrl + `/?jsonurl=${contentId}&type=${contentType}`;
}

export function buildPublishedURL(endOfUrl: string, contentType: ContentType): string {
  return config.publishedUrl + `/${contentType}/${endOfUrl}`;
}

export function buildImageURL(contentId: string, imageName: string): string {
  return config.imageS3Bucket + `/${contentId}/${imageName}`;
}
