declare const apiUrl: string;
declare const websiteUrl: string;
declare const auth0ClientId: string;
declare const imageS3Bucket: string;
declare const audience: string;

interface Config {
  auth0Domain: string;
  auth0ClientId: string;
  audience: string;
  contentApiUrl: string;
  imageApiUrl: string;
  contactApiUrl: string;
  apiUrl: string;
  previewUrl: string;
  publishedUrl: string;
  imageS3Bucket: string;
}

const productionConfig: Config = {
  auth0Domain: "contenthub.eu.auth0.com",
  auth0ClientId: auth0ClientId,
  audience: audience,
  contentApiUrl: apiUrl + "/content",
  imageApiUrl: apiUrl + "/presignedurl",
  contactApiUrl: apiUrl + "/contacts",
  apiUrl: apiUrl,
  previewUrl: websiteUrl + "/preview",
  publishedUrl: websiteUrl,
  imageS3Bucket: imageS3Bucket,
};

export const config: Config = productionConfig;
