import axios, { AxiosError } from "axios";
import { ImageListType, ImageType } from "react-images-uploading";

import { IContact } from "src/components/contactOverview/contacts";
import { IContentInformation } from "src/components/contentOverview/uploadedContent";
import { config } from "src/configuration";
import { ContentStatus, ContentType } from "src/configuration/types";

import { Notify, fireAlertFailedDelete, fireAlertFailedUpload, fireToastSuccessfullyUploaded } from "./notification";

interface IDbEntry {
  id: string;
  contentType: ContentType;
  title: string;
  images: number;
  contentStatus: ContentStatus;
  endOfUrl: string;
  content?: string;
  publishedEndOfUrl?: string;
  lastModified: number;
  createdDate: number;
  publishedAt: number;
}

interface IUniquenessResult {
  isUnique: boolean;
  uniqueEndOfUrl?: IDbEntry[];
}

// TODO: omit "undefined", handle errors differently (with typed error classes)
export async function postUploadedJSON(json: Object): Promise<IContentInformation | undefined> {
  try {
    const result = await axios.post(config.contentApiUrl, json);
    const resultData = await getContentByID(result.data);
    fireToastSuccessfullyUploaded();
    return resultData;
  } catch (error) {
    const err = error as AxiosError;
    if (err.response?.status === 409) {
      handleDuplicates(err.response.data as IUniquenessResult);
    } else {
      fireAlertFailedUpload();
    }
  }
}

export async function getContentByID(id: string): Promise<IContentInformation> {
  const result = await axios.get<IDbEntry>(config.contentApiUrl + `/${id}`);
  return transformIDbEntryToIContentInformation(result.data);
}

export async function publishContentByID(id: string): Promise<void> {
  await axios.post(config.contentApiUrl + `/${id}/publish`);
}

export async function archiveContentByID(id: string): Promise<void> {
  await axios.post(config.contentApiUrl + `/${id}/archive`);
}

export async function deleteContentByID(id: string): Promise<void> {
  await axios.delete(config.contentApiUrl + `/${id}`);
}

export async function postUploadedImages(uploadedImages: ImageListType, id: string) {
  await Promise.all(uploadedImages.map((image) => postImage(image, id)));
}

async function postImage(image: ImageType, id: string) {
  const file = image.file;
  if (file) {
    const requestObject = {
      fileName: file.name,
      fileType: file.type,
      id: id,
    };
    try {
      const response = await axios.post(config.imageApiUrl, requestObject);
      await axios.put(response.data, file, { headers: { "Content-Type": file.type } });
    } catch {
      Notify.error("Image upload fehlgeschlagen");
    }
  }
}

export async function deleteImages(imageNames: string[], id: string) {
  try {
    if (imageNames.length > 0) {
      imageNames.forEach((value) => {
        deleteImage(value, id);
      });
    }
  } catch {
    fireAlertFailedDelete();
  }
}

async function deleteImage(imageName: string, id: string) {
  await axios.delete(config.contentApiUrl + `/${id}/images/${imageName}`);
}

export async function getAllContent(): Promise<IContentInformation[]> {
  try {
    const result = await axios.get<IDbEntry[]>(config.contentApiUrl);
    return result.data.map((value) => transformIDbEntryToIContentInformation(value));
  } catch {
    Notify.error("Abfrage aller Contents aus der Datenbank fehlgeschlagen");
  }
  return [];
}

function transformIDbEntryToIContentInformation(dbEntry: IDbEntry): IContentInformation {
  const modifiedDate = new Date(dbEntry.lastModified);
  const createdDate = new Date(dbEntry.createdDate);
  const publishedAt = new Date(dbEntry.publishedAt);

  return {
    contentName: dbEntry.title,
    contentStatus: dbEntry.contentStatus,
    imageAmount: dbEntry.images,
    contentType: dbEntry.contentType,
    id: dbEntry.id,
    endOfUrl: dbEntry.endOfUrl,
    content: dbEntry.content,
    publishedEndOfUrl: dbEntry.publishedEndOfUrl,
    lastModified: modifiedDate,
    createdDate: createdDate,
    publishedAt: publishedAt,
  };
}

const handleDuplicates = (data: IUniquenessResult) => {
  let errorMessage: string[] = [];
  if (data.uniqueEndOfUrl && data.uniqueEndOfUrl.length > 0) {
    errorMessage.push("Die Kombination aus dem Typen und dem Parameter endOfUrl existiert bereits.");
    errorMessage.push("<b>Typ: </b>" + data.uniqueEndOfUrl[0].contentType);
    errorMessage.push("<b>Gespeicherte endOfUrl: </b>" + data.uniqueEndOfUrl[0].endOfUrl);
    if (
      data.uniqueEndOfUrl[0].publishedEndOfUrl &&
      data.uniqueEndOfUrl[0].endOfUrl !== data.uniqueEndOfUrl[0].publishedEndOfUrl
    ) {
      errorMessage.push("<b>Bereits veröffentlichte endOfUrl: </b>" + data.uniqueEndOfUrl[0].publishedEndOfUrl);
    }
  }
  fireAlertFailedUpload(errorMessage.join("<br>"));
};

export async function getAllContacts(): Promise<IContact[]> {
  try {
    const result = await axios.get<IContact[]>(config.contactApiUrl);
    return result.data;
  } catch (e) {
    Notify.error("Abfrage aller Kontaktanfragen aus der Datenbank fehlgeschlagen");
    throw e;
  }
}
