import React from "react";

import { NavLink } from "react-router-dom";

import "src/styling/scss/navigationBar.scss";

import { LogoutButton } from "./logoutButton";

export const NavigationBar: React.FC = () => {
  const className = (isActive: boolean): string => {
    if (isActive) return "menuButton active";
    else return "menuButton";
  };

  return (
    <nav className="menu">
      <NavLink to="/upload" className={({ isActive }) => className(isActive)}>
        Neuer Upload
      </NavLink>
      <NavLink to="/content" className={({ isActive }) => className(isActive)}>
        Hochgeladener Content
      </NavLink>
      <NavLink to="/contacts" className={({ isActive }) => className(isActive)}>
        Kontaktanfragen
      </NavLink>
      <LogoutButton />
    </nav>
  );
};
