import React from "react";

import {
  faArchive,
  faCheckCircle,
  faExclamationTriangle,
  faPencilRuler,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "src/components/button";
import { ContentStatus } from "src/configuration/types";

import { IContentInformation } from "./uploadedContent";

import { IFilter } from ".";

interface filterProps {
  setFilterInformation: (filter: IFilter) => void;
  allContent: IContentInformation[];
  filterInformation: IFilter;
}

export const Filter: React.FC<filterProps> = ({ setFilterInformation, allContent, filterInformation }) => {
  const filterStatus = (status: ContentStatus) => {
    let filteredContent = getFilteredStatusList(status, allContent);
    if (filterInformation.filterText !== "") {
      filteredContent = getFilteredTextList(filterInformation.filterText, filteredContent);
    }
    setFilterInformation({
      filteredContent: filteredContent,
      filterStatus: status,
      filterText: filterInformation.filterText,
    });
  };

  const unfilterStatus = () => {
    setFilterInformation({
      filteredContent: getFilteredTextList(filterInformation.filterText, allContent),
      filterStatus: null,
      filterText: filterInformation.filterText,
    });
  };

  const handleChange = (filterText: string): void => {
    let filteredContent = getFilteredTextList(filterText, allContent);
    if (filterInformation.filterStatus !== null) {
      filteredContent = getFilteredStatusList(filterInformation.filterStatus, filteredContent);
    }
    setFilterInformation({
      filteredContent: filteredContent,
      filterStatus: filterInformation.filterStatus,
      filterText: filterText,
    });
  };

  return (
    <div className="filterBox">
      <div className="textRow">
        <FontAwesomeIcon icon={faSearch} className="searchIcon" title="Entwurf" />
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            id="inputText"
            type="text"
            className="inputField"
            placeholder="CONTENT DURCHSUCHEN"
            onChange={(event) => {
              handleChange(event.currentTarget.value);
            }}
          />
        </form>
      </div>
      <div className="buttonRow">
        <Button
          name="Entwurf"
          className={filterInformation.filterStatus === "draft" ? "filterButton active" : "filterButton"}
          icon={faPencilRuler}
          iconPosition="left"
          onClick={filterInformation.filterStatus === "draft" ? () => unfilterStatus() : () => filterStatus("draft")}
        />
        <Button
          name="Veröffentlicht"
          className={filterInformation.filterStatus === "published" ? "filterButton active" : "filterButton"}
          icon={faCheckCircle}
          iconPosition="left"
          onClick={
            filterInformation.filterStatus === "published" ? () => unfilterStatus() : () => filterStatus("published")
          }
        />
        <Button
          name="In Überarbeitung"
          className={filterInformation.filterStatus === "rework" ? "filterButton active" : "filterButton"}
          icon={faExclamationTriangle}
          iconPosition="left"
          onClick={filterInformation.filterStatus === "rework" ? () => unfilterStatus() : () => filterStatus("rework")}
        />
        <Button
          name="Archiv"
          className={filterInformation.filterStatus === "archived" ? "filterButton active" : "filterButton"}
          icon={faArchive}
          iconPosition="left"
          onClick={
            filterInformation.filterStatus === "archived" ? () => unfilterStatus() : () => filterStatus("archived")
          }
        />
      </div>
    </div>
  );
};

export const getFilteredStatusList = (
  status: ContentStatus | null,
  filterContent: IContentInformation[]
): IContentInformation[] => {
  return filterContent.filter((value) => value.contentStatus === status);
};

export const getFilteredTextList = (
  filterText: string,
  filterContent: IContentInformation[]
): IContentInformation[] => {
  let filteredContent: IContentInformation[];
  filteredContent = filterContent.filter((value) => {
    return value.contentName.toLocaleLowerCase().trim().includes(filterText.toLocaleLowerCase().trim());
  });
  return filteredContent;
};
