import React from "react";

import logo from "src/images/cosee_logo_farbig_negativ_rgb.png";
import "src/styling/scss/header.scss";

const Header: React.FunctionComponent = () => {
  return (
    <div className="container">
      <h1 className="title">
        Contenthub
        <img src={logo} alt="Logo_cosee" className="logo" />
      </h1>
    </div>
  );
};

export default Header;
