import React, { useState } from "react";

import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  name: string;
  title?: string;
  border?: string;
  color?: string;
  height?: string;
  onClick: () => Promise<void> | void;
  width?: string;
  margin?: string;
  id?: string;
  className?: string;
  icon?: IconDefinition;
  iconPosition?: "left" | "right" | "center";
  float?: "left" | "right" | "none" | "inherit";
  disabled?: boolean;
}

const Button: React.FC<Props> = ({
  name,
  title,
  border,
  color,
  height,
  onClick,
  width,
  margin,
  id,
  className,
  icon,
  iconPosition = "none",
  float = "none",
  disabled = false,
}) => {
  const [processing, setProcessing] = useState(false);
  const faIcon = icon ? <FontAwesomeIcon icon={icon} /> : null;
  const content = (
    <>
      {iconPosition === "left" && faIcon}
      <span id={iconPosition}>{name}</span>
      {iconPosition === "right" && faIcon}
      {iconPosition === "center" && faIcon}
    </>
  );

  return (
    <button
      onClick={async () => {
        setProcessing(true);
        try {
          await onClick();
        } finally {
          setProcessing(false);
        }
      }}
      name={name}
      title={title}
      id={id}
      className={className}
      style={{
        backgroundColor: color,
        border,
        height,
        width,
        margin: margin,
        float: float,
      }}
      disabled={disabled || processing}
    >
      {content}
    </button>
  );
};

export default Button;
