import { Notify } from "./notification";
import { IArticle } from "./validator/validateJSON";


export function downloadFile(data: IArticle | null, fileType: string): void {
  if (data !== null) {
    const blob = new Blob([JSON.stringify(data)], { type: fileType });

    const a = document.createElement("a");
    a.download = data.header.endOfUrl + ".json";
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  } else {
    Notify.error("Download fehlgeschlagen");
  }
}
