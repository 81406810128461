import { useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { AxiosInstance } from "axios";

import { config } from "src/configuration";

export function useGlobalRegisterAuthenticationInterceptor(axios: AxiosInstance): void {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const interceptorId = axios.interceptors.request.use(async (requestConfig) => {
      if (requestConfig.url?.startsWith(config.apiUrl)) {
        const token = await getAccessTokenSilently();
        requestConfig.headers = {
          ...requestConfig.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      return requestConfig;
    });
    return () => {
      axios.interceptors.request.eject(interceptorId);
    };
  }, [getAccessTokenSilently, axios]);
}
