import Ajv2020 from "ajv/dist/2020";

import { ContentType } from "src/configuration/types";

import * as headerSchema from "./schemas/header.schema.json";
import * as interviewSchema from "./schemas/interview.schema.json";
import * as referenceSchema from "./schemas/reference.schema.json";

export interface IArticle {
  header: {
    type: ContentType;
    endOfUrl: string;
  };
}

export interface ParsedValidationResult {
  content: IArticle;
  images: IReferencedImage[];
}

export const buildAjv = () => {
  return new Ajv2020({ allErrors: true, schemas: [referenceSchema, interviewSchema, headerSchema] });
};

const ajv = buildAjv();

export const validateJSON = (jsonString: string): string[] | ParsedValidationResult => {
  let content: any;
  try {
    content = JSON.parse(jsonString);
  } catch (error) {
    return ["Unable to parse JSON: " + error];
  }

  const type = getType(content);
  if (type === null) {
    return [
      "Bitte gib einen Content-Type (z.B. references, interviews, jobs) im Header der JSON-Datei unter dem Parameter 'type' an!",
    ];
  }

  if (!ajv.validate(type, content)) {
    if (ajv.errors !== undefined && ajv.errors !== null && ajv.errors.length > 0) {
      return ajv.errors.map(
        (e) =>
          e.instancePath +
          " " +
          e.message +
          (Object.keys(e.params).length > 0
            ? "\n" +
              Object.keys(e.params)
                .map((key) => "- " + key + ": " + e.params[key])
                .join("\n")
            : "")
      );
    } else {
      return ["An unknown error occurred during JSON validation"];
    }
  }

  const images: IReferencedImage[] = extractImages(content);
  const filenames = images.map((i) => i.filename);
  const duplicates = images.filter((image, index) => filenames.indexOf(image.filename) !== index);
  if (duplicates.length > 0) {
    return ["Found duplicate images: " + duplicates.map((ri) => ri.filename).join(", ")];
  }

  const sliderMissingAltText = images.some((image) => image.isSliderImage && !image.sliderImageHasAltText);
  if (sliderMissingAltText) {
    return ["Missing alternative text for slider images"];
  }

  return {
    images: images,
    content: content,
  };
};

export interface IReferencedImage {
  filename: string;
  jsonPath: string;
  isSliderImage?: boolean;
  sliderImageHasAltText?: boolean;
}

export const extractImages = (obj: any, parent?: string): IReferencedImage[] => {
  let images: IReferencedImage[] = [];
  for (const key in obj) {
    const value = obj[key];
    const path = parent !== undefined ? parent + "." + key : key;
    if (typeof value === "string") {
      if (isImageFilename(value)) {
        images.push({ filename: value, jsonPath: path });
      }
    } else if (typeof value === "object") {
      images.push(...(key === "slider" ? extractSliderImages(value, path) : extractImages(value, path)));
    }
  }
  return images;
};

const extractSliderImages = (obj: any, parentPath: string): IReferencedImage[] => {
  let sliderImages: IReferencedImage[] = [];
  if (obj.length < 1) {
    return sliderImages;
  }

  obj.forEach((slider: any, sliderIndex: number) => {
    slider["imagesUrl"].forEach((imageUrl: string, imageIndex: number) => {
      const path = `${parentPath}.${sliderIndex}.imagesUrl.${imageIndex}`;
      if (isImageFilename(imageUrl)) {
        const hasAltText = slider["imagesAltTexts"] !== undefined && slider["imagesAltTexts"][imageIndex] !== undefined;
        sliderImages.push({
          filename: imageUrl,
          jsonPath: path,
          isSliderImage: true,
          sliderImageHasAltText: hasAltText,
        });
      }
    });
  });

  return sliderImages;
};

export const getType = (content: any): ContentType | null => {
  try {
    return content.header.type || null;
  } catch {
    return null;
  }
};

const imageFileExtensions = [".jpg", ".jpeg", ".png", ".bmp", ".gif", "webp"];

export function isImageFilename(potentialFilename: string) {
  const normalizedName = potentialFilename.toLocaleLowerCase().trimEnd();
  return imageFileExtensions.some((extension) => normalizedName.endsWith(extension));
}
//extract only the names without the paths
export function getImageNamesWithoutPath(imagesArray: string[]): string[] {
  return imagesArray.map((image) => getImageName(image));
}

export function getImageName(imagePath: string): string {
  const imagePathAsArray: string[] = imagePath.trimEnd().split("/");
  return imagePathAsArray[imagePathAsArray.length - 1];
}
